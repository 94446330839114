import * as yup from "yup";
import { Message } from "yup/lib/types";

import { passwordScore, validate } from "./string";

declare module "yup" {
  interface StringSchema {
    password(message?: Message): this;
    phone(message?: Message): this;
    phoneCode(message?: Message): this;
    date(format: string, message?: Message): this;
    zipCode(message?: Message): this;
    ssn(message?: Message): this;
    itin(message?: Message): this;
    birthYear(message?: Message): this;
  }
}

yup.addMethod(yup.string, "password", function (message) {
  return this.test("password", message, async function (value) {
    const score = !value
      ? 0
      : this.options.context?.string?.password?.score ||
        (await passwordScore(value));

    return score > 0;
  });
});

yup.addMethod(yup.string, "email", function (message) {
  return this.test(
    "phone",
    message,
    (value) =>
      // Make email optional by default, should use .required to enforce
      // requirement
      !value || validate.email(value)
  );
});

yup.addMethod(yup.string, "phone", function (message) {
  return this.test("phone", message, (value) => validate.phone(value));
});

yup.addMethod(yup.string, "phoneCode", function (message) {
  return this.test("phoneCode", message, (value = "") =>
    /\d{4}/.test(value.replace(/\D/g, ""))
  );
});

yup.addMethod(yup.string, "date", function (format, message) {
  return this.test("date", message, (value) => validate.date(value, format));
});

yup.addMethod(yup.string, "zipCode", function (message) {
  return this.test("zipCode", message, (value) =>
    /^\d{5}-?(\d{4})?$/.test(value)
  );
});

yup.addMethod(yup.string, "ssn", function (format, message) {
  return this.test(
    "ssn",
    message,
    (value) => value.replace(/[^0-9]/g, "").length === 9
  );
});

yup.addMethod(yup.string, "itin", function (format, message) {
  return this.test(
    "itin",
    message,
    (value) => value.replace(/[^0-9]/g, "").length === 9 && value[0] === "9"
  );
});

yup.addMethod(yup.string, "birthYear", function (format, message) {
  return this.test(
    "birthYear",
    message,
    (value) => value.replace(/[^0-9]/g, "").length === 4
  );
});

export default yup;
